<template>
  <div id="wrapper">
    <header class="container">
      <Logo/>
      <Navigation/>
    </header>

    <main class="container">
      <transition name="main" mode="out-in">
        <router-view/>
      </transition>
    </main>

    <footer>
      <div class="container">
        <div class="row">
          <div class="column">
            <h4 class="headline">Contact Me</h4>
            <p>Feel free to hit me up via mail
              <br>
              <a href="mailto:contact@filipbasara.net">contact@filipbasara.net</a>
            </p>
          </div>
          <div class="column">
            <h4 class="headline">Follow Me</h4>
            <p>
              You can follow me on
              <a href="https://twitter.com/filipbasara" target="_blank">Twitter</a>,
              <a href="https://github.com/filipbasara" target="_blank">GitHub</a>
              and
              <a href="https://dribbble.com/filipbasara" target="_blank">Dribbble</a>
            </p>
          </div>
          <div class="column">
            <h4 class="headline">Recommended</h4>
            <p>
              <a href="https://jamstack.org" target="_blank">JAMStack</a>&ndash; a more secure reliable way to build websites
            </p>
            <p>
              <a href="https://thenewdynamic.com" target="_blank">The New Dynamic</a> &ndash; curated JAMStack resources
            </p>
            <p>
              <a href="https://gridsome.org" target="_blank">Gridsome</a> &ndash; A static site generator based on Vue
            </p>
          </div>
        </div>
      </div>
      <div id="footer-bar">
        <div class="container">
          <div class="row">
            <div class="column">
              <div style=" display: flex;">
                <strong>&copy; Copyright {{ new Date().getFullYear()}} &ndash; Filip Basara</strong>
                <div class="meta" style="margin-left: auto;">
                  <router-link style=" color: white; margin: 0 1rem" to="/imprint">Imprint</router-link>
                  <router-link style=" color: white; margin: 0 1rem" to="/privacy">Privacy</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import Logo from "@/components/Logo";

export default {
  components: {
    Navigation,
    Logo
  }
};
</script>

<style lang="scss">
@import "@/assets/css/reset.scss";
@import "@/assets/css/grid.scss";
@import "@/assets/css/vars.scss";
@import "~inter-ui/inter-ui.css";

html,
body,
#app,
#wrapper {
  height: 100%;
}
body {
  font-family: $font;
  font-size: $font-size;
  line-height: 1.5;
}
#wrapper {
  width: 100%;
  margin: 0 auto;
}
header {
  display: flex;
  justify-content: flex-start;
  padding: 2rem 4rem;
  background-color: white;
  align-items: center;
  height: 120px;
}

@media screen and (max-width: 40rem) {
  header {
    flex-direction: column;
    height: auto;
  }
  h1 {
    font-size: 2.25rem;
  }
  h2 {
    font-size: 1.75rem;
  }
}

.main-enter-active,
.main-leave-active {
  transition: all 0.55s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.main-enter, .main-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(80px);
  opacity: 0;
}

main {
  padding: 4rem;
}

footer {
  background-color: $black;
  border-top: 0.25rem solid $grey;
  color: $grey;
  & > .container {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  & p {
    font-size: 1rem;
  }
  & a {
    color: white;
  }
  & .headline {
    color: white;
    margin-bottom: 1.25rem;
  }
  & #footer-bar {
    color: white;
    background-color: $primary;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media screen and (max-width: 1400px) {
  main #container {
    flex-direction: column;
  }
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin-bottom: 2rem;
}

p {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.headline {
  color: $black;
  font-weight: 700;
  margin-bottom: 0.25rem;
}

.subline {
  color: $grey;
  font-weight: 500;
}

.text-centered {
  text-align: center;
}

main a {
  color: $primary;
  transition: all 0.3s ease;
  position: relative;
  display: inline;
  line-height: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

article {
  margin: 4rem 0rem;
}

ul {
  list-style-type: none;
  margin-left: 0px;
  padding-left: 0px;
  & li {
    padding-bottom: 1rem;
    line-height: 1.5;
  }
}

small {
  color: $grey;
  font-weight: 500;
  font-size: 0.85rem;
}

hr.separator {
  margin: 4rem 0;
  border: 1px solid #f8f8f8;
}

.contact {
  margin: 4rem 0rem;
  padding: 1.5rem 2rem;
  width: 100%;
  border-left: 0.5rem solid $primary;
  & p {
    margin: 0px;
    font-size: 1.5rem;
    font-weight: 700;
    color: $black;
  }
}

.skill {
  & h4 {
    margin-bottom: 0.5rem;
  }
  & .icon {
    margin-bottom: 2rem;
  }
}

figure.project {
  position: relative;
  box-shadow: 0px 10px 20px 0px rgba($color: $primary, $alpha: 0.075);
  & figcaption {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2.5rem;
    width: 100%;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    & .headline {
      font-size: 2rem;
      margin-bottom: 0.25rem;
      color: $black;
      font-weight: 700;
    }
    & .subline {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
    & a {
      font-size: 1.25rem;
    }
  }
}
</style>