<template>
  <nav id="main-nav">
    <router-link to="/">Home</router-link>
    <router-link to="/about">About Me</router-link>
    <router-link to="/work">Work</router-link>
    <a href="https://github.com/filipbasara" target="_blank" title="Filip Basara on GitHub">GitHub</a>
    <a
      href="https://dribbble.com/filipbasara"
      target="_blank"
      title="Filip Basara on Dribbble"
    >Dribbble</a>
  </nav>
</template>

<script>
export default {};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/vars.scss";
#main-nav {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  & a {
    margin: 0 1rem;
    font-size: 1.2rem;
    line-height: 1;
    font-weight: 500;
    color: $grey;
    transition: 0.2s ease;
    text-decoration: none;
    transition-property: color, transform;

    &:hover,
    &.router-link-exact-active {
      color: $black;
      transition: 0.2s ease;
      transition-property: color, transform;
    }
  }
}

@media screen and (max-width: 40rem) {
  #main-nav {
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    white-space: nowrap; /* [1] */
    overflow-x: auto; /* [2] */
    -webkit-overflow-scrolling: touch; /* [3] */
    -ms-overflow-style: -ms-autohiding-scrollbar;
    &:after {
      content: "";
      width: 8rem;
      height: 1rem;
      display: block;
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      position: absolute;
      right: 0px;
      pointer-events: none;
    }
  }
  #main-nav a {
    font-size: 1rem;
    margin: 0 0.5rem;
    white-space: nowrap;
    line-height: 1rem;
    vertical-align: middle;
  }
}
</style>
