
<template>
  <div class="home">
    <article class="row">
      <div class="column column-50">
        <h1 class="headline">Hello, I'm Filip!
          <br>UI Designer &amp;
          <br>Front-End Developer
        </h1>
        <h3 class="subline">I design and code interfaces for the web.</h3>
        <p>With over 5 years of experience in the professional space as a Designer and Developer I am able to create interfaces that are easy to use and visually appealing.</p>
        <p>
          Usually, I work for agencies and help businesses reach their business goals with delightful interfaces. I am currently employed at
          <a
            href="https://loft-48.de"
            target="_blank"
          >LOFT48°</a>.
        </p>

        <p>
          <router-link to="/work">Check out my work</router-link>
        </p>
        <div class="contact">
          <p>
            Want to chat?
            <a href="mailto:contact@filipbasara.de">
              <br>Shoot me a message
            </a>
          </p>
        </div>
      </div>
      <div class="column column-50">
        <figure class="project">
          <img
            src="@/assets/img/projects/filipbasara_gabee_interface_home_mock.jpg"
            alt="Hand holding up a smartphone that shows a mobile application"
          >
          <figcaption>
            <h3 class="headline">GaBee</h3>
            <h4 class="subline">AI-powered personal assistant</h4>
            <router-link to="/work">View Project</router-link>
          </figcaption>
        </figure>
      </div>
    </article>
    <h2 class="headline">Skills</h2>
    <h3 class="subline">Solutions I provide at a glance</h3>
    <article class="row">
      <div class="column skill">
        <img class="icon" width="64" height="64" src="@/assets/img/icons/design.svg" alt>
        <h4>User Interface Design</h4>
        <p>I'm a proponent of legible UIs that are easy to understand and use. The UIs I create follow logical patterns and are visually pleasing.</p>
      </div>
      <div class="column skill">
        <img class="icon" width="64" height="64" src="@/assets/img/icons/coding.svg" alt>
        <h4>Front-End Development</h4>
        <p>Using modern development technologies and methodologies, I create responsive interfaces both in speed and layout</p>
      </div>
      <div class="column skill">
        <img class="icon" width="64" height="64" src="@/assets/img/icons/speedometer.svg" alt>
        <h4>Optimization</h4>
        <p>Good interfaces have to be accessible and semantically structured. I provide solutions to make interfaces work better with screenreaders and search engines.</p>
      </div>
    </article>
    <h2 class="headline">Tools</h2>
    <h3 class="subline">Software that enables great results</h3>
    <article class="row">
      <div class="column skill">
        <h4>Design tools</h4>
        <p>Depending on the platform I either use Sketch or Figma. While Figma is multi-platform and certainly easier to use when collaborating, the Sketch ecosystem is unmatched and it is overall my favorite tool for any UI Design related task.</p>
      </div>
      <div class="column skill">
        <h4>Protoyping</h4>
        <p>For prototyping and hand-off I found Marvel (marvelapp.com) to be the most reliable tool out there. With it's ever-growing set of features and very competetive price-point it's an easy sell for me.</p>
      </div>
      <div class="column skill">
        <h4>Development</h4>
        <p>I primarily build interfaces with either plain HTML / CSS / Vanilla JS (ES5+) or with Vue. Tools like SASS or PostCSS help me style UIs much more sytematically. I also use Contao CMS or Wordpress for marketing and company sites to have an better editorial experience.</p>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: "home",
  components: {}
};
</script>

<style lang="scss" scoped>
</style>


